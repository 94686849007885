export const countryNames = [
  "afghanistan",
  "af",
  "aland islands",
  "ax",
  "albania",
  "al",
  "algeria",
  "dz",
  "american samoa",
  "as",
  "andorra",
  "ad",
  "angola",
  "ao",
  "anguilla",
  "ai",
  "antigua",
  "ag",
  "argentina",
  "ar",
  "armenia",
  "am",
  "aruba",
  "aw",
  "australia",
  "au",
  "austria",
  "at",
  "azerbaijan",
  "az",
  "bahamas",
  "bs",
  "bahrain",
  "bh",
  "bangladesh",
  "bd",
  "barbados",
  "bb",
  "belarus",
  "by",
  "belgium",
  "be",
  "belize",
  "bz",
  "benin",
  "bj",
  "bermuda",
  "bm",
  "bhutan",
  "bt",
  "bolivia",
  "bo",
  "bosnia",
  "ba",
  "botswana",
  "bw",
  "bouvet island",
  "bv",
  "brazil",
  "br",
  "british virgin islands",
  "vg",
  "brunei",
  "bn",
  "bulgaria",
  "bg",
  "burkina faso",
  "bf",
  "burma",
  "mm",
  "myanmar",
  "burundi",
  "bi",
  "caicos islands",
  "tc",
  "cambodia",
  "kh",
  "cameroon",
  "cm",
  "canada",
  "ca",
  "cape verde",
  "cv",
  "cayman islands",
  "ky",
  "central african republic",
  "cf",
  "chad",
  "td",
  "chile",
  "cl",
  "china",
  "cn",
  "christmas island",
  "cx",
  "cocos islands",
  "cc",
  "colombia",
  "co",
  "comoros",
  "km",
  "congo",
  "cd",
  "congo brazzaville",
  "cg",
  "cook islands",
  "ck",
  "costa rica",
  "cr",
  "cote divoire",
  "ci",
  "croatia",
  "hr",
  "cuba",
  "cu",
  "cyprus",
  "cy",
  "czech republic",
  "cz",
  "denmark",
  "dk",
  "djibouti",
  "dj",
  "dominica",
  "dm",
  "dominican republic",
  "do",
  "ecuador",
  "ec",
  "egypt",
  "eg",
  "el salvador",
  "sv",
  "equatorial guinea",
  "gq",
  "eritrea",
  "er",
  "estonia",
  "ee",
  "ethiopia",
  "et",
  "europeanunion",
  "eu",
  "falkland islands",
  "fk",
  "faroe islands",
  "fo",
  "fiji",
  "fj",
  "finland",
  "fi",
  "france",
  "fr",
  "french guiana",
  "gf",
  "french polynesia",
  "pf",
  "french territories",
  "tf",
  "gabon",
  "ga",
  "gambia",
  "gm",
  "georgia",
  "ge",
  "germany",
  "de",
  "ghana",
  "gh",
  "gibraltar",
  "gi",
  "greece",
  "gr",
  "greenland",
  "gl",
  "grenada",
  "gd",
  "guadeloupe",
  "gp",
  "guam",
  "gu",
  "guatemala",
  "gt",
  "guinea",
  "gn",
  "guinea-bissau",
  "gw",
  "guyana",
  "gy",
  "haiti",
  "ht",
  "heard island",
  "hm",
  "honduras",
  "hn",
  "hong kong",
  "hk",
  "hungary",
  "hu",
  "iceland",
  "is",
  "india",
  "in",
  "indian ocean territory",
  "io",
  "indonesia",
  "id",
  "iran",
  "ir",
  "iraq",
  "iq",
  "ireland",
  "ie",
  "israel",
  "il",
  "italy",
  "it",
  "jamaica",
  "jm",
  "jan mayen",
  "sj",
  "svalbard",
  "japan",
  "jp",
  "jordan",
  "jo",
  "kazakhstan",
  "kz",
  "kenya",
  "ke",
  "kiribati",
  "ki",
  "kuwait",
  "kw",
  "kyrgyzstan",
  "kg",
  "laos",
  "la",
  "latvia",
  "lv",
  "lebanon",
  "lb",
  "lesotho",
  "ls",
  "liberia",
  "lr",
  "libya",
  "ly",
  "liechtenstein",
  "li",
  "lithuania",
  "lt",
  "luxembourg",
  "lu",
  "macau",
  "mo",
  "macedonia",
  "mk",
  "madagascar",
  "mg",
  "malawi",
  "mw",
  "malaysia",
  "my",
  "maldives",
  "mv",
  "mali",
  "ml",
  "malta",
  "mt",
  "marshall islands",
  "mh",
  "martinique",
  "mq",
  "mauritania",
  "mr",
  "mauritius",
  "mu",
  "mayotte",
  "yt",
  "mexico",
  "mx",
  "micronesia",
  "fm",
  "moldova",
  "md",
  "monaco",
  "mc",
  "mongolia",
  "mn",
  "montenegro",
  "me",
  "montserrat",
  "ms",
  "morocco",
  "ma",
  "mozambique",
  "mz",
  "namibia",
  "na",
  "nauru",
  "nr",
  "nepal",
  "np",
  "netherlands",
  "nl",
  "netherlandsantilles",
  "an",
  "new caledonia",
  "nc",
  "new guinea",
  "pg",
  "new zealand",
  "nz",
  "nicaragua",
  "ni",
  "niger",
  "ne",
  "nigeria",
  "ng",
  "niue",
  "nu",
  "norfolk island",
  "nf",
  "north korea",
  "kp",
  "northern mariana islands",
  "mp",
  "norway",
  "no",
  "oman",
  "om",
  "pakistan",
  "pk",
  "palau",
  "pw",
  "palestine",
  "ps",
  "panama",
  "pa",
  "paraguay",
  "py",
  "peru",
  "pe",
  "philippines",
  "ph",
  "pitcairn islands",
  "pn",
  "poland",
  "pl",
  "portugal",
  "pt",
  "puerto rico",
  "pr",
  "qatar",
  "qa",
  "reunion",
  "re",
  "romania",
  "ro",
  "russia",
  "ru",
  "rwanda",
  "rw",
  "saint helena",
  "sh",
  "saint kitts and nevis",
  "kn",
  "saint lucia",
  "lc",
  "saint pierre",
  "pm",
  "saint vincent",
  "vc",
  "samoa",
  "ws",
  "san marino",
  "sm",
  "sandwich islands",
  "gs",
  "sao tome",
  "st",
  "saudi arabia",
  "sa",
  "scotland",
  "gb sct",
  "senegal",
  "sn",
  "serbia",
  "cs",
  "serbia",
  "rs",
  "seychelles",
  "sc",
  "sierra leone",
  "sl",
  "singapore",
  "sg",
  "slovakia",
  "sk",
  "slovenia",
  "si",
  "solomon islands",
  "sb",
  "somalia",
  "so",
  "south africa",
  "za",
  "south korea",
  "kr",
  "spain",
  "es",
  "sri lanka",
  "lk",
  "sudan",
  "sd",
  "suriname",
  "sr",
  "swaziland",
  "sz",
  "sweden",
  "se",
  "switzerland",
  "ch",
  "syria",
  "sy",
  "taiwan",
  "tw",
  "tajikistan",
  "tj",
  "tanzania",
  "tz",
  "thailand",
  "th",
  "timorleste",
  "tl",
  "togo",
  "tg",
  "tokelau",
  "tk",
  "tonga",
  "to",
  "trinidad",
  "tt",
  "tunisia",
  "tn",
  "turkey",
  "tr",
  "turkmenistan",
  "tm",
  "tuvalu",
  "tv",
  "u.a.e.",
  "ae",
  "united arab emirates",
  "uganda",
  "ug",
  "ukraine",
  "ua",
  "united kingdom",
  "gb",
  "united states",
  "us",
  "america",
  "uruguay",
  "uy",
  "us minor islands",
  "um",
  "us virgin islands",
  "vi",
  "uzbekistan",
  "uz",
  "vanuatu",
  "vu",
  "vatican city",
  "va",
  "venezuela",
  "ve",
  "vietnam",
  "vn",
  "wales",
  "gb wls",
  "wallis and futuna",
  "wf",
  "western sahara",
  "eh",
  "yemen",
  "ye",
  "zambia",
  "zm",
  "zimbabwe",
  "zw",
];
